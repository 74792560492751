/* eslint-disable */

import axios from "axios";
const Api_Dashboard = axios.create({
  baseURL: "https://api.examero.com/api_dashboard",
});



Api_Dashboard.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
Api_Dashboard.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
    }
    return Promise.reject(error);
  }
);

export default Api_Dashboard;
