/* eslint-disable */

import React, { useEffect } from "react";
import Notification from "../Notification";

const SupervisorsActivity = () => {
  const man = true;
  return <Notification api={"/activity/supervisor"} man={man} />;
};

export default SupervisorsActivity;
